import axios from 'axios'
import router from '../plugin/router'
import { ElMessage } from 'element-plus'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const request = axios.create({
	baseURL: process.env.VUE_APP_BASE_URL,
	timeout: 10000
})

request.interceptors.request.use(
	config => {
		let token = localStorage.getItem("access_token");
		if (token) {
			config.headers['Authorization'] = token;
		}
		return config
	},
	error => {
		return Promise.reject(error)
	}
)

request.interceptors.response.use(
	response => {
		let code = parseInt(response.data.code);
		if (code === 100003 || code === 100004 || code === 100005) {
			ElMessage.error("令牌无效,请先登录");
			setTimeout(function () {
				localStorage.removeItem("access_token");
				router.push("/login");
				return false;
			}, 500);
		} else return response.data
	}, () => {
		ElMessage.error("网络异常,无法连接至服务器");
		throw "网络异常";
	}
)

export default request
