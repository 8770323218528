import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: () => {
            return { path: '/work' }
        },
    },
    buildRouter('/login', 'Login', '/single/login', '登录', false),
    buildRouter('/work', 'Work', '/single/work', '工作台'),
    buildRouter('/patient', 'PatientList', '/patient/list', '随访患者'),
    buildRouter('/patient/:id', 'PatientInfo', '/patient/info', '患者详情'),
    buildRouter('/patient/:id/plan', 'PatientPlan', '/patient/plan', '随访计划'),
    buildRouter('/patient/:id/follow', 'PatientFollow', '/patient/follow', '随访详情'),
    buildRouter('/external', 'ExternalList', '/external/list', '外院复诊'),
    buildRouter('/chat', 'ChatList', '/chat/list', '留言咨询'),
    buildRouter('/chat/:id', 'ChatInfo', '/chat/info', '留言详情'),
    buildRouter('/user', 'UserList', '/single/user', '用户管理'),
    buildRouter('/hospital', 'Hospital', '/single/hospital', '医院管理'),
    buildRouter('/template', 'TemplateList', '/template/list', '模板管理'),
    buildRouter('/template/:id', 'TemplateInfo', '/template/info', '编辑模板内容'),
    buildRouter('/test', 'Test', '/single/test', '检测项目'),
    buildRouter('/setting', 'SettingList', '/single/setting', '系统设置'),
    buildRouter('/:pathMatch(.*)', 'NotFound', '/single/404', '页面不存在')
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

function buildRouter(path, name, file, title, auth) {
    return {
        path,
        name,
        component: () => import('../views' + file),
        meta: { title, auth: auth === undefined ? true : auth }
    }
}

router.beforeEach((to, from, next) => {
    if (!to.meta.auth || Boolean(localStorage.getItem("access_token")) || to.meta.title == "登录") {
        document.title = (to.meta.title === undefined ? '未知页面 - ' : to.meta.title + ' - ') + '随访管理系统'
        next()
    } else {
        next('/login')
    }
})

export default router
