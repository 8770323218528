export const date = {
    format: (timestamp, code) => {
        let dateObj = new Date(parseInt(timestamp));
        const opt = {
            "y+": dateObj.getFullYear().toString(),        // 年
            "m+": (dateObj.getMonth() + 1).toString(),     // 月
            "d+": dateObj.getDate().toString(),            // 日
            "H+": dateObj.getHours().toString(),           // 时
            "M+": dateObj.getMinutes().toString(),         // 分
            "S+": dateObj.getSeconds().toString()          // 秒
        }
        for (let k in opt) {
            let ret = new RegExp("(" + k + ")").exec(code);
            if (ret) code = code.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
        return code;
    }
}