import { createStore } from 'vuex'

export default createStore({
  state: {
    nav: false
  },
  mutations: {
    hideNav(state){
      state.nav = false;
    },
    showNav(state){
      state.nav = true;
    },
  },
  actions: {
  },
  modules: {
  }
})
