import router from '../plugin/router'
import { ElMessage } from 'element-plus'

let socket = {
    connect: () => {
        let token = localStorage.getItem("access_token");
        if (!token) {
            socket.exitLogin();
            return null;
        }
        return new WebSocket(process.env.VUE_APP_WS_URL + token);
    },
    init(task, open,error,close){
        task.onopen = open;
        task.onerror = error;
        task.onclose = close;
    },
    send: (task, action, data) => {
        if(!data) data = {};
        data.action = action;
        task.send(JSON.stringify(data));
    },
    receive: (task, callback) => {
        task.onmessage = msg => {
            let data = JSON.parse(msg.data);
            if (data.code == 100003 || data.code == 100005) socket.exitLogin();
            else if (!data.state) ElMessage.warning(data.message + ' error')
            else callback(data);
        }
    },
    exitLogin: () => {
        ElMessage.error("令牌无效,请先登录");
        localStorage.removeItem("access_token");
        router.push("/login");
    }
}

export default socket;