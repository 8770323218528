import { createApp } from 'vue'
import 'element-plus/theme-chalk/display.css'
import 'element-plus/dist/index.css'
import router from './plugin/router'
import store from './plugin/store'
import ep from 'element-plus'
import app from './App.vue'

import VueBMap, { initBMapApiLoader } from 'vue-bmap-gl';
import 'vue-bmap-gl/dist/style.css'

initBMapApiLoader({
    ak: '6k4ShS1zNbq9ZLEXVf8XdWu1PpfxTH0c'
})

createApp(app).use(router).use(store).use(ep)
    .use(VueBMap).mount('#app')