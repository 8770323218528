<template>
  <div id="compatibility" :class="{'hide':hide}" v-if="show">
    <div class="icon">
      <el-icon><iphone /></el-icon>
    </div>
    <div class="tips">不支持小屏访问</div>
    <div class="sub-tips">建议使用<strong>10.2英寸</strong>分辨率<strong>1024x768</strong>以上的设备</div>
    <div class="sub-tips">平板请横屏使用,或使用其他设备访问</div>
    <el-button class="tips-btn" size="large" @click="close">不接受建议,继续访问</el-button>
  </div>
</template>

<script>
import {Iphone} from "@element-plus/icons";

export default {
  name: "Compatibility",
  components: {Iphone},
  data: () => ({
    show: true,
    hide: false
  }),
  methods:{
    close(){
      this.hide = true;
      setTimeout(()=>{
        this.show = false;
      },400);
    }
  }
}
</script>

<style scoped>
#compatibility {
  transition: all ease-out 0.3s;
  background-color: #f4f4f4;
  position: fixed;
  display: none;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  left: 0;
  top: 0;
}

#compatibility.hide{
  opacity: 0;
}

#compatibility .icon {
  margin-bottom: 30px;
  text-align: center;
  margin-top: 15vh;
}

#compatibility .icon i,#compatibility .icon svg {
  height: 200px;
  width: 200px;
}

.tips{
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
}

.sub-tips{
  text-align: center;
  font-size: 14px;
}

.tips-btn{
  left: calc(50vw - 85px);
  position: absolute;
  bottom: 10vh;
}
</style>