<template>
  <compatibility />
  <navigation @open-notice="$refs.notice.open()" />
  <notice ref="notice" />
  <el-scrollbar height="100vh">
    <el-config-provider :locale="locale">
      <router-view v-slot="{ Component }">
        <transition name="el-fade-in-linear" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </el-config-provider>
  </el-scrollbar>
</template>

<script>
import compatibility from "./components/common/compatibility.vue";
import Navigation from "./components/common/navigation.vue";
import zhCn from "element-plus/lib/locale/lang/zh-cn";
import Notice from "./components/common/notice.vue";
import { system } from "./plugin/api";

export default {
  components: { compatibility, Navigation, Notice },
  name: "App",
  data: () => ({
    locale: zhCn,
    version: "1.0.0",
  }),
  methods: {
    init() {
      if (localStorage.getItem("access_token")) this.$store.commit("showNav");
      this.queryVersion();
    },
    queryVersion() {
      system.queryVersion().then((res) => {
        if (this.version !== res.message) {
          this.$notify({
            title: "系统版本更新",
            dangerouslyUseHTMLString: true,
            message: `
            <div>系统有新的版本可用, 您可点击下方按钮或刷新页面来更新系统.</div>
            <div style="width:120px" class="mt-5 mb-10 float-right">最新版本: <b>${res.message}</b></div>
            <div class="mt-5 mb-10">当前版本: <b>${this.version}</b></div>
            <button onclick="javascript:location.reload();" class="el-button el-button--primary el-button--default" type="button" style="width: 104%; --el-button-bg-color:#409eff; --el-button-border-color:#409eff; --el-button-hover-bg-color:rgb(102, 177, 255); --el-button-hover-border-color:rgb(102, 177, 255); --el-button-active-bg-color:rgb(58, 142, 230); --el-button-active-border-color:rgb(58, 142, 230);">立即更新</button>
            `,
            position: "bottom-left",
            showClose: false,
            duration: 0,
          });
        }
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
@import "style/app.css";
</style>
