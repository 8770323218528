<template>
  <div class="navigation no-select" :class="{ open: show }">
    <div class="title-box">
      <img class="logo" alt="Logo" src="../../assets/logo.png" />
      <div class="title">随访管理系统</div>
    </div>
    <div class="menu-list">
      <div
        class="menu-item"
        :class="{ active: active === index }"
        v-for="(item, index) in list"
        :key="'nav_' + index"
        @click="openPage(index, item.path)"
      >
        <el-icon>
          <data-analysis v-if="item.icon === 'analysis'" />
          <suitcase v-else-if="item.icon === 'suitcase'" />
          <tickets v-else-if="item.icon === 'tickets'" />
          <chat-dot-square v-else-if="item.icon === 'chat-dot-square'" />
          <odometer v-else-if="item.icon === 'odometer'" />
          <setting v-else-if="item.icon === 'setting'" />
          <user v-else-if="item.icon === 'user'" />
          <office-building v-else-if="item.icon === 'office-building'" />
          <document-copy v-else-if="item.icon === 'document-copy'" />
          <pointer v-else-if="item.icon === 'pointer'" />
          <cpu v-else-if="item.icon === 'cpu'" />
        </el-icon>
        <div>{{ item.name }}</div>
      </div>
      <div class="bottom">
        <div class="menu-item">
          <img class="avatar" alt="医生头像" src="img/avatar/doctor.png" />
          <div class="name">{{ name }}</div>
        </div>
        <div class="menu-item small" @click="openNotice">
          <el-icon><bell /></el-icon>
        </div>
        <div class="menu-item small" @click="exit">
          <el-icon>
            <switch-button />
          </el-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { common } from "../../plugin/api";
import socket from "../../plugin/socket";
import {
  DataAnalysis,
  ChatDotSquare,
  Tickets,
  Suitcase,
  Odometer,
  Setting,
  User,
  OfficeBuilding,
  DocumentCopy,
  Pointer,
  SwitchButton,
  Bell,
  Cpu,
} from "@element-plus/icons";

export default {
  name: "Navigation",
  components: {
    DataAnalysis,
    ChatDotSquare,
    Tickets,
    Suitcase,
    Setting,
    Odometer,
    User,
    OfficeBuilding,
    DocumentCopy,
    Pointer,
    SwitchButton,
    Bell,
    Cpu,
  },
  emits: ["open-notice"],
  data: () => ({
    show: false,
    name: "加载中",
    list: [
      {
        name: "工作台",
        icon: "analysis",
        path: "/work",
      },
      {
        name: "随访患者",
        icon: "suitcase",
        path: "/patient",
      },
      {
        name: "外院复诊",
        icon: "tickets",
        path: "/external",
      },
      {
        name: "留言咨询",
        icon: "chat-dot-square",
        path: "/chat",
      },
      {
        name: "用户管理",
        icon: "user",
        path: "/user",
      },
      {
        name: "医院管理",
        icon: "office-building",
        path: "/hospital",
      },
      {
        name: "模板管理",
        icon: "document-copy",
        path: "/template",
      },
      {
        name: "检测项目",
        icon: "cpu",
        path: "/test",
      },
      // {
      //   name: "操作日志",
      //   icon: "pointer",
      //   path: "/log",
      // },
      {
        name: "系统设置",
        icon: "setting",
        path: "/setting",
      },
    ],
    active: -1,
    auto: -1,
    talk: null,
    notify: null,
    noticeMap: {},
  }),
  methods: {
    init() {
      let path = this.$route.fullPath.replace("/", "");
      if (path !== "") {
        if (path.indexOf("/") !== -1)
          path = path.substring(0, path.indexOf("/"));
        for (let i = 0; i < this.list.length; i++) {
          if (this.list[i].path === "/" + path) {
            this.active = i;
            return true;
          }
        }
      }
    },
    initData() {
      if (localStorage.getItem("access_token")) {
        setTimeout(() => this.initSocket(), 2000);
        common.getName().then((res) => {
          this.name = res.state ? res.message : "加载失败";
        });
      }
    },
    openPage(index, path) {
      this.active = index;
      this.$router.push(path);
    },
    exit() {
      this.$confirm("退出登录前请确认没有正在提交的操作", "退出登录", {
        confirmButtonText: "确认退出",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message.success({ message: "注销成功", center: true });
        common.logout();
        setTimeout(() => {
          localStorage.removeItem("access_token");
          this.$store.commit("hideNav");
          this.$router.push("/login");
        }, 500);
      });
    },
    openNotice() {
      this.$emit("open-notice");
    },
    initSocket() {
      if (localStorage.getItem("access_token")) {
        if (this.notify) this.notify.close();
        this.notify = this.$notify({
          title: "通知服务",
          message: "正在连接服务器...",
          position: "bottom-right",
          showClose: false,
          duration: 0,
          type: "info",
        });
        this.task = socket.connect();
        socket.init(
          this.task,
          this.socketOpen,
          this.socketError,
          this.socketClose
        );
        socket.receive(this.task, this.receive);
      }
    },
    socketOpen() {
      setTimeout(() => {
        if (this.notify) this.notify.close();
        this.notify = null;
        this.$notify({
          title: "通知服务",
          message: "已成功连接至通知服务器.",
          position: "bottom-right",
          duration: 2500,
          type: "success"
        });
        socket.send(this.task,"openNotice",{});
      }, 1000);
    },
    socketError() {
      setTimeout(() => {
        if (this.notify) this.notify.close();
        this.notify = null;
        this.$notify({
          title: "通知服务",
          message: "无法连接到通知服务器.",
          position: "bottom-right",
          duration: 0,
          type: "error",
        });
      }, 1000);
    },
    socketClose() {
      if (localStorage.getItem("access_token")) {
        try {
          this.task.close();
        } catch {
          console.log("通知服务断开失败");
        }
        if (this.notify) this.notify.close();
        this.notify = this.$notify({
          title: "通知服务",
          message: "连接中断,10秒后自动重连...",
          position: "bottom-right",
          showClose: false,
          type: "warning",
          duration: 0,
        });
        if (this.auto > -1) clearTimeout(this.auto);
        this.auto = setTimeout(() => {
          this.initSocket();
        }, 10000);
      }
    },
    receive(res) {
      if (res.state && res.message === "notice") {
        let data = res.data;
        let config = {
          title: data.title,
          dangerouslyUseHTMLString: true,
          message: `
            <div>${data.content}</div>
            <button class="notice-btn el-button el-button--primary el-button--default" type="button">查看详情</button>
            `,
          position: "bottom-right",
          duration: 5000,
        };
        let path = this.buildPath(data.action,data.relation);
        if (path)
          config.onClick = () => {
            this.$router.push(path);
            if (this.noticeMap[data.id]) this.noticeMap[data.id].close();
            delete this.noticeMap[data.id];
          };
        this.noticeMap[data.id] = this.$notify(config);
      }
    },
    buildPath(action,relation){
      if (action == "NEW_PROBLEM") return "/chat/" + relation;
      if (action == "NEW_PATIENT") return "/patient/" + relation;
      return "";
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route: {
      handler() {
        this.init();
      },
      deep: true,
    },
    "$store.state.nav": function (newData, oldData) {
      if (newData !== oldData) {
        this.show = newData;
        if (this.show) this.initData();
        else if (this.task) this.task.close();
      }
    },
  },
};
</script>

<style scoped>
.navigation {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  transition: all ease-out 0.5s;
  background-color: #354141;
  position: fixed;
  height: 100vh;
  left: -145px;
  z-index: 9;
  top: 0;
}

.navigation.open {
  left: 0;
}

.menu-list {
  height: calc(100vh - 150px);
  position: relative;
  padding: 10px;
}

.menu-item {
  transition: all ease-out 0.3s;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  color: #fff;
  width: 100px;
}

.menu-item.small {
  padding: 10px 9px 10px 19px;
  display: inline-block;
  margin-bottom: 0;
  width: auto;
}

.menu-list > .bottom {
  position: absolute;
  bottom: 0px;
  left: 10px;
}

.menu-item i {
  margin-right: 10px;
  font-size: 22px;
}

.title-box {
  padding: 14px 9px 10px 9px;
  text-align: center;
  color: #fff;
}

.title-box .title {
  font-weight: bold;
  font-size: 16px;
}

.title-box .logo {
  background-color: #f4f4f4;
  border-radius: 100px;
  padding: 5px;
  height: 60px;
  width: 60px;
}

.menu-item:hover {
  background-color: #09a39e;
  color: #f4f4f4;
}

.menu-item:active,
.menu-item.active {
  background-color: #17c8c2;
  color: #f4f4f4;
}

.avatar {
  background-color: #c5c5c5;
  border-radius: 4px;
  margin-right: 5px;
  padding: 1px;
  height: 28px;
  width: 28px;
}

.name {
  text-align: center;
  width: 65px;
}
</style>