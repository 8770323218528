import request from './request'
import device from 'device'
import md5 from 'js-md5'

// 设备工具
export const deviceUtil = {
	get: function () {
		let info = device(navigator.userAgent, { parseUserAgent: true });
		return "[浏览器/" + info.parser.useragent.family + "]" + this.uuid();
	},
	uuid: function () {
		const crypto = window.crypto || window.msCrypto;
		let build = localStorage.getItem("uuid");
		if (build === undefined || build === null || build === "") {
			build = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
				let r = crypto.getRandomValues(new Uint32Array(1))[0] / 100 / 100 / 100 / 100 | 0,
					v = c === 'x' ? r : (r & 0x3 | 0x8);
				return v.toString(16);
			});
			localStorage.setItem("uuid", build.toLocaleUpperCase());
		}
		return build.toLocaleUpperCase();
	}
}

// 通用接口
export const common = {
	getHostUrl: () => {
		return process.env.VUE_APP_BASE_URL;
	},
	initPage: path => {
		return request({
			url: '/page' + path,
			method: 'GET'
		})
	},
	login: form => {
		let data = new URLSearchParams();
		data.append('phone', form.phone);
		data.append('password', md5(form.password));
		data.append('code', form.code);
		data.append('remember', form.remember);
		data.append('device', deviceUtil.get());
		return request({
			url: '/doctor/login',
			method: 'POST',
			data
		})
	},
	logout: () => {
		return request({
			url: '/doctor/logout',
			method: 'GET'
		})
	},
	getName: () => {
		return request({
			url: '/doctor/name',
			method: 'GET'
		})
	},
	searchHospital: key => {
		return request({
			url: '/hospital/search/' + key,
			method: 'GET'
		})
	}
}

// 医生接口
export const doctor = {
	getNumber: form => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.hospital) data.append('hospital', form.hospital);
		if (form.department) data.append('department', form.department);
		if (form.group) data.append('group', form.group);
		if (form.phone) data.append('phone', form.phone);
		if (form.state) data.append('state', form.state);
		return request({
			url: '/doctor/number',
			method: 'POST',
			data
		})
	},
	getList: (form, page, number) => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.hospital) data.append('hospital', form.hospital);
		if (form.department) data.append('department', form.department);
		if (form.group) data.append('group', form.group);
		if (form.phone) data.append('phone', form.phone);
		if (form.state) data.append('state', form.state);
		data.append('page', page);
		data.append('number', number);
		return request({
			url: '/doctor/list',
			method: 'POST',
			data
		})
	},
	add: form => {
		let data = new URLSearchParams();
		data.append('name', form.name);
		data.append('phone', form.phone);
		data.append('hospital', form.hospital);
		data.append('department', form.department);
		data.append('post', form.post);
		data.append('password', md5(form.password));
		data.append('frozen', form.frozen);
		data.append('authority', form.authority);
		if (form.wid != undefined) data.append('wid', form.wid);
		if (form.title != undefined) data.append('title', form.title == '' ? 0 : form.title);
		if (form.group != undefined) data.append('group', form.group == '' ? 0 : form.group);
		if (form.code != undefined) data.append('code', form.code);
		return request({
			url: '/doctor/add',
			method: 'POST',
			data
		})
	},
	update: form => {
		let data = new URLSearchParams();
		if (form.id) data.append('id', form.id);
		if (form.name != undefined) data.append('name', form.name);
		if (form.wid != undefined) data.append('wid', form.wid);
		if (form.phone != undefined) data.append('phone', form.phone);
		if (form.title != undefined) data.append('title', form.title == '' ? 0 : form.title);
		if (form.hospital != undefined) data.append('hospital', form.hospital);
		if (form.department != undefined) data.append('department', form.department);
		if (form.group != undefined) data.append('group', form.group == '' ? 0 : form.group);
		if (form.post != undefined) data.append('post', form.post);
		if (form.password != undefined) data.append('password', md5(form.password));
		if (form.code != undefined) data.append('code', form.code);
		if (form.frozen != undefined) data.append('frozen', form.frozen);
		if (form.authority != undefined) data.append('authority', form.authority);
		return request({
			url: '/doctor/update',
			method: 'POST',
			data
		})
	},
	offline: id => {
		return request({
			url: '/doctor/offline/' + id,
			method: 'GET'
		})
	}
}

// 医院接口
export const hospital = {
	getNumber: form => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.cooperation != undefined) data.append('cooperation', form.cooperation);
		if (form.included != undefined) data.append('included', form.included);
		return request({
			url: '/hospital/number',
			method: 'POST',
			data
		})
	},
	getList: (form, page, number) => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.cooperation != undefined) data.append('cooperation', form.cooperation);
		if (form.included != undefined) data.append('included', form.included);
		data.append('page', page);
		data.append('number', number);
		return request({
			url: '/hospital/list',
			method: 'POST',
			data
		})
	},
	add: form => {
		let data = new URLSearchParams();
		if (form.mid) data.append('mid', form.mid);
		if (form.name) data.append('name', form.name);
		if (form.province) data.append('province', form.province);
		if (form.city) data.append('city', form.city);
		if (form.district) data.append('district', form.district);
		if (form.address) data.append('address', form.address);
		if (form.location) data.append('location', form.location);
		return request({
			url: '/hospital/add',
			method: 'POST',
			data
		})
	},
	updateCooperation: (id, state) => {
		let data = new URLSearchParams();
		if (id) data.append('id', id);
		if (state != undefined) data.append('state', state);
		return request({
			url: '/hospital/cooperation',
			method: 'POST',
			data
		})
	}
}

// 随访接口
export const follow = {
	getTemplateNumber: form => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.type) data.append('type', form.type);
		return request({
			url: '/follow/template/number',
			method: 'POST',
			data
		})
	},
	getTemplateList: (form, page, number) => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.type) data.append('type', form.type);
		data.append('page', page);
		data.append('number', number);
		return request({
			url: '/follow/template/list',
			method: 'POST',
			data
		})
	},
	getTidList: tid => {
		return request({
			url: '/follow/template/list/' + tid,
			method: 'GET'
		})
	},
	getTemplate: id => {
		return request({
			url: '/follow/template/' + id,
			method: 'GET'
		})
	},
	getPlan: id => {
		return request({
			url: '/follow/plan/' + id,
			method: 'POST'
		})
	},
	updatePlan: (form) => {
		let data = new URLSearchParams();
		data.append('id', form.id);
		data.append('pid', form.pid);
		if (form.based) data.append('based', form.based);
		if (form.tips) data.append('tips', form.tips);
		if (form.remarks) data.append('remarks', form.remarks);
		if (form.content) data.append('content', form.content);
		return request({
			url: '/follow/plan/update',
			method: 'POST',
			data
		})
	},
	addTemplate: form => {
		let data = new URLSearchParams();
		data.append('name', form.name);
		data.append('transplant', form.type);
		data.append('based', form.based);
		return request({
			url: '/follow/template/create',
			method: 'POST',
			data
		})
	},
	removeTemplate: id => {
		let data = new URLSearchParams();
		return request({
			url: '/follow/template/remove?id=' + id,
			method: 'GET',
			data
		})
	},
	updateTemplateContent: (id, content) => {
		let data = new URLSearchParams();
		data.append('id', id);
		data.append('content', content);
		return request({
			url: '/follow/template/update/content',
			method: 'POST',
			data
		})
	},
	updateTemplateBasics: form => {
		let data = new URLSearchParams();
		data.append('id', form.id);
		data.append('name', form.name);
		data.append('transplant', form.type);
		data.append('based', form.based);
		return request({
			url: '/follow/template/update/basics',
			method: 'POST',
			data
		})
	},
	getInfo: fid => {
		return request({
			url: '/follow/' + fid + '/info',
			method: 'GET'
		})
	},
	getNumber: pid => {
		let data = new URLSearchParams();
		data.append('pid', pid);
		return request({
			url: '/follow/number',
			method: 'POST',
			data
		})
	},
	getList: (pid, page, number) => {
		let data = new URLSearchParams();
		data.append('pid', pid);
		data.append('page', page);
		data.append('number', number);
		return request({
			url: '/follow/list',
			method: 'POST',
			data
		})
	},
	getExternalNumber: form => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.type) data.append('type', form.type);
		if (form.transplant) data.append('transplant', form.transplant);
		if (form.state) data.append('state', form.state);
		return request({
			url: '/follow/external/number',
			method: 'POST',
			data
		})
	},
	getExternalList: (form, page, number) => {
		let data = new URLSearchParams();
		if (form.name) data.append('name', form.name);
		if (form.type) data.append('type', form.type);
		if (form.transplant) data.append('transplant', form.transplant);
		if (form.state) data.append('state', form.state);
		data.append('page', page);
		data.append('number', number);
		return request({
			url: '/follow/external/list',
			method: 'POST',
			data
		})
	},
	getFeedback: fid => {
		return request({
			url: '/follow/feedback/' + fid,
			method: 'GET'
		})
	},
	submitFeedback: (type, form) => {
		let data = new URLSearchParams();
		for (let key in form) {
			data.append(key, form[key]);
		}
		return request({
			url: '/follow/feedback/' + type,
			method: 'POST',
			data
		})
	}
}

// 系统接口
export const system = {
	cleanCache: () => {
		return request({
			url: '/system/clean/cache',
			method: 'GET'
		})
	},
	cleanUser: () => {
		return request({
			url: '/system/clean/user',
			method: 'GET'
		})
	},
	cleanSocket: () => {
		return request({
			url: '/system/clean/socket',
			method: 'GET'
		})
	},
	querySetting: code => {
		return request({
			url: '/system/setting/' + code,
			method: 'GET'
		})
	},
	updateSetting: (code, form) => {
		let data = new URLSearchParams();
		data.append('data', form);
		return request({
			url: '/system/setting/' + code,
			method: 'POST',
			data
		})
	},
	testConnect: () => {
		return request({
			url: '/system/connect',
			method: 'GET'
		})
	},
	testSpeed: () => {
		return request({
			url: '/system/speed',
			method: 'GET'
		})
	},
	queryVersion: () => {
		return request({
			url: '/system/version/web',
			method: 'GET'
		})
	},
}

// 指标接口
export const index = {
	getList: () => {
		return request({
			url: '/index/list',
			method: 'GET'
		})
	},
	updateItem: (form, addList, updateList, deleteList) => {
		let data = new URLSearchParams();
		data.append('id', form.id);
		if (form.name) data.append('name', form.name);
		if (form.weight != undefined) data.append('weight', form.weight);
		if (form.script != undefined) data.append('script', form.script);
		if (addList.length > 0) data.append('addList', JSON.stringify(addList));
		if (updateList.length > 0) data.append('updateList', JSON.stringify(updateList));
		if (deleteList.length > 0) data.append('deleteList', JSON.stringify(deleteList));
		return request({
			url: '/index/update',
			method: 'POST',
			data
		})
	},
	addItem: form => {
		let data = new URLSearchParams();
		data.append('name', form.name);
		data.append('weight', form.weight);
		if (form.script != undefined) data.append('script', form.script);
		data.append('list', JSON.stringify(form.list));
		return request({
			url: '/index/add',
			method: 'POST',
			data
		})
	},
	removeItem: id => {
		return request({
			url: '/index/remove?id=' + id,
			method: 'GET'
		})
	},
	getDailyTestList: form => {
		let data = new URLSearchParams();
		data.append('pid', form.pid);
		data.append('start', form.start);
		if (form.end) data.append('end', form.end);
		else data.append('end', new Date().getTime());
		return request({
			url: '/index/info/list',
			method: 'POST',
			data
		})
	},
	getDailyTestTemp: pid => {
		return request({
			url: '/index/temp?pid=' + pid,
			method: 'GET'
		})
	},
	getSingleItemList: (tid, pid) => {
		return request({
			url: '/index/list/item/' + tid + '?pid=' + pid,
			method: 'GET'
		})
	},
}

// 患者接口
export const patient = {
	getNumber: form => {
		let data = new URLSearchParams();
		if (form.patient) data.append('patient', form.patient);
		if (form.transplantType) data.append('type', form.transplantType);
		if (form.dateType) data.append('dateType', form.dateType);
		if (form.date) {
			data.append('start', form.date[0].getTime());
			if (form.date[0].getTime() == form.date[1].getTime()) {
				let end = form.date[1];
				end.setHours(23)
				end.setMinutes(59)
				data.append('end', end.setSeconds(59));
			} else data.append('end', form.date[1].getTime());
		}
		return request({
			url: '/patient/number',
			method: 'POST',
			data
		})
	},
	getList: (form, page, number) => {
		let data = new URLSearchParams();
		if (form.patient) data.append('patient', form.patient);
		if (form.transplantType) data.append('type', form.transplantType);
		if (form.dateType) data.append('dateType', form.dateType);
		if (form.date) {
			data.append('start', form.date[0].getTime());
			if (form.date[0].getTime() == form.date[1].getTime()) {
				let end = form.date[1];
				end.setHours(23)
				end.setMinutes(59)
				data.append('end', end.setSeconds(59));
			} else data.append('end', form.date[1].getTime());
		}
		data.append('number', number);
		data.append('page', page);
		return request({
			url: '/patient/list',
			method: 'POST',
			data
		})
	},
	getInfo: (id, analysis) => {
		return request({
			url: '/patient/' + id + "/info?analysis=" + analysis,
			method: 'GET'
		})
	},
	examine: (id, state, message) => {
		let data = new URLSearchParams();
		data.append('id', id);
		data.append('state', state);
		data.append('message', message);
		return request({
			url: '/patient/examine',
			method: 'POST',
			data
		})
	},
	getExamineList: () => {
		return request({
			url: '/patient/examine/list',
			method: 'GET'
		})
	},
	getFollowList: () => {
		return request({
			url: '/patient/follow/list',
			method: 'GET'
		})
	}
}

// 通知接口
export const notice = {
	sendPatientFollow: id => {
		return request({
			url: '/notice/send/patient-follow?id=' + id,
			method: 'GET'
		})
	},
	getList: (page, number) => {
		let data = new URLSearchParams();
		data.append('number', number);
		data.append('page', page);
		return request({
			url: '/notice/list',
			method: 'POST',
			data
		})
	}
}

// 咨询接口
export const problem = {
	getNumber: form => {
		let data = new URLSearchParams();
		if (form.title) data.append('title', form.title);
		if (form.name) data.append('name', form.name);
		if (form.type) data.append('type', form.type);
		if (form.state) data.append('state', form.state);
		if (form.date) {
			data.append('start', form.date[0].getTime());
			if (form.date[0].getTime() == form.date[1].getTime()) {
				let end = form.date[1];
				end.setHours(23)
				end.setMinutes(59)
				data.append('end', end.setSeconds(59));
			} else data.append('end', form.date[1].getTime());
		}
		return request({
			url: '/problem/number',
			method: 'POST',
			data
		})
	},
	getList: (form, page, number) => {
		let data = new URLSearchParams();
		if (form.title) data.append('title', form.title);
		if (form.name) data.append('name', form.name);
		if (form.type) data.append('type', form.type);
		if (form.state) data.append('state', form.state);
		if (form.start) data.append('start', form.start);
		if (form.date) {
			data.append('start', form.date[0].getTime());
			if (form.date[0].getTime() == form.date[1].getTime()) {
				let end = form.date[1];
				end.setHours(23)
				end.setMinutes(59)
				data.append('end', end.setSeconds(59));
			} else data.append('end', form.date[1].getTime());
		}
		data.append('number', number);
		data.append('page', page);
		return request({
			url: '/problem/list',
			method: 'POST',
			data
		})
	},
	getInfo: id => {
		return request({
			url: '/problem/' + id + '/info',
			method: 'GET'
		})
	},
	getNewTalk: () => {
		return request({
			url: '/problem/talk/new',
			method: 'GET'
		})
	}
}