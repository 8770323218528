<template>
  <el-drawer
    custom-class="notice-drawer no-select"
    v-model="show"
    :with-header="false"
    direction="rtl"
    :size="400"
  >
    <div class="drawer-head">
      <el-icon><bell-filled /></el-icon>
      <span>消息通知</span>
    </div>
    <div class="clean">
      <el-icon><brush /></el-icon>
    </div>
    <div class="notice-list" v-loading="loading">
      <el-scrollbar height="calc(100vh - 155px)" v-if="list.length > 0">
        <div
          class="notice-item"
          v-for="(item, i) in list"
          :key="i"
          @click="openPage(item.action, item.relation)"
        >
          <el-badge :is-dot="!item.read">
            <img
              class="notice-icon"
              alt="通知图标"
              :src="'img/notice/' + item.icon + '.png'"
            />
          </el-badge>
          <div>
            <div class="notice-title">{{ item.title }}</div>
            <div class="notice-date">{{ item.time }}</div>
            <div class="notice-content">{{ item.content }}</div>
          </div>
        </div>
        <!-- <div class="notice-item">
          <el-badge hidden is-dot>
            <img class="notice-icon" alt="通知图标" src="img/notice/date.png" />
          </el-badge>
          <div>
            <div class="notice-title">今日有 3 人计划随访</div>
            <div class="notice-date">2022-02-10 11:12:30</div>
          </div>
        </div>
        <div class="notice-item">
          <el-badge is-dot>
            <img
              class="notice-icon"
              alt="通知图标"
              src="img/notice/patient.png"
            />
          </el-badge>
          <div>
            <div class="notice-title">您有新的随访人绑定申请</div>
            <div class="notice-date">2022-02-10 11:12:30</div>
            <div class="notice-content" style="display: flex">
              <div style="width: 150px">
                <div>姓名: 张三丰</div>
                <div>移植类型: 肾脏移植</div>
                <div>手术医生: 李四</div>
              </div>
              <div>
                <div style="margin-top: 20px">手术日期: 2021/12/15</div>
                <div>主管医生: 王五</div>
              </div>
            </div>
          </div>
        </div>
        <div class="notice-item">
          <el-badge hidden is-dot>
            <img
              class="notice-icon"
              alt="通知图标"
              src="img/notice/setting.png"
            />
          </el-badge>
          <div>
            <div class="notice-title">发现新版本 / v1.0.1</div>
            <div class="notice-date">2022-02-10 11:12:30</div>
            <div class="notice-content">
              更新内容:
              阿斯顿发比偶是对方把你欧气爆发i欧气爆发i欧气爆发啊圣诞快乐飞机
            </div>
          </div>
        </div>
        <div class="notice-item">
          <el-badge is-dot>
            <img
              class="notice-icon"
              alt="通知图标"
              src="img/notice/external.png"
            />
          </el-badge>
          <div>
            <div class="notice-title">您有新的外院复诊待反馈</div>
            <div class="notice-date">2022-02-10 11:12:30</div>
            <div class="notice-content">
              <div style="display: flex">
                <div style="width: 150px">
                  <div>患者姓名: 张三丰</div>
                  <div>就诊类型: 计划复诊</div>
                  <div>就诊日期: 2021/12/15</div>
                </div>
                <div>
                  <div>移植类型: 肾脏移植</div>
                  <div style="margin-top: 20px">接诊医生: 刘谋面</div>
                </div>
              </div>
              <div>就诊医院: 中国人民解放军联勤保障部队第八三医院</div>
            </div>
          </div>
        </div>
        <div class="notice-item">
          <el-badge hidden is-dot>
            <img
              class="notice-icon"
              alt="通知图标"
              src="img/notice/system.png"
            />
          </el-badge>
          <div>
            <div class="notice-title">系统通知</div>
            <div class="notice-date">2022-02-10 11:12:30</div>
            <div class="notice-content">排班安排、操作提示、维护通知...</div>
          </div>
        </div> -->
      </el-scrollbar>
      <el-empty
        description="暂无通知"
        v-else
        style="padding: calc(50vh - 184.5px) 0px;"
      ></el-empty>
    </div>
    <div class="btn">查看全部</div>
  </el-drawer>
</template>

<script>
import { BellFilled, Brush } from "@element-plus/icons";
import { notice } from "../../plugin/api";
import { date } from "../../plugin/util";

export default {
  name: "Notice",
  components: { BellFilled, Brush },
  data: () => ({
    show: false,
    loading: true,
    list: [],
  }),
  methods: {
    open() {
      this.show = true;
      this.loading = true;
      this.queryList();
    },
    close() {
      this.loading = false;
      this.show = false;
    },
    queryList() {
      notice
        .getList(1, 20)
        .then((res) => {
          setTimeout(() => {
            for (let i in res.data) {
              let item = res.data[i];
              item.icon = this.buildIcon(item.action);
              item.time = date.format(item.time, "yyyy-mm-dd HH:MM:SS");
            }
            this.list = res.data;
            this.loading = false;
          }, 500);
        })
        .catch(() => {
          this.loading = false;
          this.show = false;
        });
    },
    buildIcon(action) {
      switch (action) {
        case "NEW_PROBLEM":
          return "chat";
        case "NEW_PATIENT":
          return "patient";
      }
    },
    openPage(action, relation) {
      let path = "";
      if (action == "NEW_PROBLEM") path = "/chat/" + relation;
      if (action == "NEW_PATIENT") path = "/patient/" + relation;
      if (path) this.$router.push(path);
      this.show = false;
    },
  },
};
</script>

<style scoped>
.drawer-head {
  align-items: center;
  font-weight: bold;
  color: #516161;
  font-size: 18px;
  display: flex;
}

.drawer-head i {
  margin-right: 5px;
  font-size: 26px;
}

.clean {
  transition: all ease-out 0.3s;
  background-color: #f4f4f4;
  transform: rotate(180deg);
  justify-content: center;
  border-radius: 100px;
  align-items: center;
  position: absolute;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  color: #999;
  height: 30px;
  width: 30px;
  right: 20px;
  top: 20px;
}

.clean:hover {
  background-color: #e2e2e2;
  transform: rotate(200deg);
  color: #505050;
}

.clean:active {
  background-color: #d1d0d0;
  transform: rotate(160deg);
  color: #333;
}

.notice-list {
  padding: 15px 0 10px 0;
}

.notice-item {
  transition: all ease-out 0.3s;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  padding: 10px;
}

.notice-item:hover {
  background-color: #ececec;
}

.notice-item:active {
  background-color: #e6e6e6;
}

.btn {
  font-size: 16px;
  padding: 8px;
}

.notice-icon {
  background-color: #fff;
  border-radius: 8px;
  margin-right: 5px;
  height: 35px;
  width: 35px;
}

.notice-date {
  color: #7a7a7a;
  font-size: 13px;
}

.notice-content {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  display: -webkit-box;
  overflow: hidden;
  margin-top: 5px;
  font-size: 14px;
}
</style>

<style>
.notice-drawer {
  height: calc(100% - 25px) !important;
  margin: 10px 10px 0 0;
  border-radius: 8px;
  width: 400px;
}

.notice-item .is-dot {
  right: 10px !important;
}
</style>